@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap');

#root {
    background-color: #fff;
}

main {
    box-sizing: border-box;
    background-color: #fff;
    min-height: 100vh;
}

.dashboard--main > div {
    padding-left: 290px;
    height: 100%;
}

.analytics--dashboard--main > div {
    padding-left: 250px;
    height: 100%;
}

@media(max-width: 720px) {
    .dashboard--main > div {
        padding-left: 0;
    }
}
